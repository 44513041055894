import './css/main.scss';

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//import Connecter from './ajax/Connecter.class';
import Connecter from './ajax/Connecter_v2.class';
import useSessionConnect from './hooks/useSessionConnect';

import BtnDeco from './Components/Elements/Default/BtnDeco';
import Loader from './Components/Elements/Default/Loader';
import ListHeros from './Components/Elements/Default/ListHeros';
import FormModifHeros from './Components/Form/FormModifHeros';
import Btns from './Components/Elements/Default/Btns';
import Menu from './Components/Elements/Menu/Menu';
import ThrowDicer from './Components/Elements/ThrowDices/ThrowDicer';

import Sommaire from './Components/Elements/Default/Sommaire';
import Logo from './Components/Elements/Default/Logo';
import Topform from './Components/Elements/Default/Topform';

import { NavProvider } from './Components/Context/Nav.context';
import { ListHerosProvider } from './Components/Context/ListHeros.context';
import { HerosProvider } from './Components/Context/SelectedHeros';
import { ThrowdicerProvider } from './Components/Context/Throwdicer.context';
import DisplaySelectedHeros from './Components/Elements/DisplayHeros/DisplaySelectedHeros';
import Popup from './Components/Elements/Popups/Popup';

function App() {
  const [params, setParams] = useSearchParams();

  //User is connect ?
  const [UserConnect, setUserConnect] = useState(false);
  const { isConnect } = useSessionConnect();
  let navigate = useNavigate();

  //Loader actif ou non
  const [Loading, setLoading] = useState(true);
  const [LoadingSimple, setLoadingSimple] = useState(false);

  //réactualise le formulaire de modif du heros
  const [ReloadModif, setReloadModif] = useState(1);

  //Menu ouvert ou non
  const [MenuOpened, setMenuOpened] = useState(false);

  //On modifie le SelectedHeros si true
  const [inModifier, setInModifier] = useState(false);

  //a l'ouverture de la page seulement
  useEffect(() => {
    if (isConnect) {
      setUserConnect(true);
    } else if (isConnect === false) {
      setUserConnect(false);
      let url = '/login';
      if (params.get('t')) {
        url = url + '?t=' + params.get('t');
      }
      navigate(url);
    }
  }, [isConnect, navigate]);

  useEffect(() => {
    if (!Loading && LoadingSimple) {
      setLoadingSimple(false);
    }
  }, [Loading, LoadingSimple]);

  function connect() {
    return new Promise((r, f) => {
      new Connecter('connectSession').connect_to_api().then(res => {
        console.log(res);
        new Connecter('verifSession').connect_to_api().then(res => {
          console.log(res);
        });
      });
    });
  }

  /**
   * Non fonctionnel
   * Permet de renvoyer l'url de partage de template
   * @param {int | string} template_id
   * @returns
   */
  function get_url_to_share_template(template_id) {
    return new Promise((r, f) => {
      let Conn = new Connecter();
      Conn.connect_to_api('get_url_to_share_template', {
        tp_id: template_id,
      }).then(r => {
        console.log(r);
      });
    });
  }

  return (
    <div className="App">
      {UserConnect && (
        <NavProvider>
          <ListHerosProvider>
            <HerosProvider
              setLoading={setLoading}
              setInModifier={setInModifier}
            >
              <ThrowdicerProvider>
                <Loader actif={Loading} />
                <Loader actif={LoadingSimple} Simple={true} />
                <Topform />
                <main>
                  <div className="containerBtns">
                    <Btns
                      ID="open_menu"
                      Icon={MenuOpened ? 'close' : 'menu'}
                      Taille="60px"
                      OnClick={e => setMenuOpened(MenuOpened ? false : true)}
                    />

                    <Btns
                      ID="modif_heros"
                      Icon={inModifier ? 'close' : 'modif_heros'}
                      Taille="50px"
                      OnClick={e => {
                        setInModifier(inModifier ? false : true);
                      }}
                    />
                  </div>

                  {!inModifier ? (
                    <DisplaySelectedHeros setInModifier={setInModifier} />
                  ) : (
                    <FormModifHeros setInModifier={setInModifier} />
                  )}
                </main>
                <aside className={MenuOpened ? 'opened' : 'closed'}>
                  <Logo />
                  <div className="scroller">
                    <Sommaire CloseMenu={e => setMenuOpened(false)} />

                    <ListHeros
                      closeMenu={e => setMenuOpened(false)}
                      setInModifier={setInModifier}
                      setLoadingSimple={setLoadingSimple}
                    />
                  </div>

                  <BtnDeco />
                </aside>

                <ThrowDicer />

                <Menu
                  inModifier={inModifier}
                  setInModifier={setInModifier}
                  setReloadModif={e => setReloadModif(ReloadModif + 1)}
                />
              </ThrowdicerProvider>
            </HerosProvider>
          </ListHerosProvider>
        </NavProvider>
      )}
    </div>
  );
}

export default App;
