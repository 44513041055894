import React from 'react';

import AllTexts from '../../Data/AllTexts.json';

import Img1 from '../../assets/homepage/img1.svg';
import Img2 from '../../assets/homepage/img2.svg';
import Img3 from '../../assets/homepage/img3.svg';

import Btns from '../Elements/Default/Btns';
import { Link } from 'react-router-dom';

import Header from '../Elements/Home/Header';
import Footer from '../Elements/Home/Footer';

const Home = () => {
  return (
    <main id="homepage" className="page-site">
      <Header />
      <section className="heros">
        <div className="inside">
          <div className="containText">
            <h1>{AllTexts.homepage.h1}</h1>

            <div className="Text">{AllTexts.homepage.maindesc}</div>

            <Link to="/app">
              <Btns Text="Essayer l'application" Icon="none" />
            </Link>
          </div>
        </div>
        <div className="bg_black"></div>
      </section>
      <section className="section1">
        <div className="inside">
          <div className="containText">
            <h2>{AllTexts.homepage.section1.titre}</h2>

            <div className="Text">{AllTexts.homepage.section1.text}</div>
          </div>

          <div className="containerImg">
            <div className="containImg">
              <img src={Img1} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section2">
        <div className="inside">
          <div className="containerImg">
            <div className="containImg">
              <img src={Img2} alt="" />
            </div>
          </div>

          <div className="containText">
            <h2>{AllTexts.homepage.section2.titre}</h2>

            <div className="Text">{AllTexts.homepage.section2.text}</div>
          </div>
        </div>
      </section>
      <section className="section3">
        <div className="inside">
          <div className="containText">
            <h2>{AllTexts.homepage.section3.titre}</h2>

            <div className="Text">{AllTexts.homepage.section3.text}</div>
          </div>

          <div className="containerImg">
            <div className="containImg">
              <img src={Img3} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section4">
        <div className="inside">
          <h2>Vous voulez optimiser vos campagnes ?</h2>

          <Link to="/app">
            <Btns Text="Essayez l'application" Icon="none" />
          </Link>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Home;
