import React from 'react';
import { useNavigate } from 'react-router-dom';

import Btns from './Btns';
import Connecter from '../../../ajax/Connecter_v2.class';

const BtnDeco = () => {
  let navigate = useNavigate();

  function deconnect() {
    return new Promise((r, f) => {
      new Connecter('deconnect').connect_to_api().then(res => {
        console.log(res);
        if (res.res) {
          navigate('/login');
        }
      });
    });
  }

  return (
    <div id="btnLogOut" className="btn">
      <Btns Icon="logout" Taille="40px" OnClick={deconnect} />
    </div>
  );
};

export default BtnDeco;
