import React from "react";

import imgLogo from "../../../assets/logo_alfred.svg";

const Logo = () => {
       return (
              <div className="Logo">
                     <img src={imgLogo} alt="" />
              </div>
       );
};

export default Logo;
