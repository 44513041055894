import React, { createContext, useState, useEffect } from 'react';

export const ThrowdicerContext = createContext();

export const ThrowdicerProvider = ({ children }) => {
  const [DicerOpen, setDicerOpen] = useState(false);
  const [DicesToThrow, setDicesToThrow] = useState({});

  const [AllThrowPrepared, setAllThrowPrepared] = useState([]);

  useEffect(() => {
    if (!AllThrowPrepared.length) {
      if (!Object.keys(DicesToThrow).length) {
        return;
      } else {
        setAllThrowPrepared([]);
        for (const key in DicesToThrow) {
          prepareThrow(false, DicesToThrow[key] + 'd' + key);
        }
      }
    }

    if (DicerOpen) {
      document.querySelector('body').addEventListener('click', handleClick);
    }

    return () => {
      if (DicerOpen) {
        document
          .querySelector('body')
          .removeEventListener('click', handleClick);
      }
    };
  }, [DicerOpen]);

  function prepareThrow(what = false, dice, bonus = false, value_what = false) {
    let d = transformDice(dice);
    bonus = recupVariable(bonus, d.valuedice, value_what);
    //decompose la demande
    let item = transformBonus(bonus, dice, value_what);

    if (!item.valuedice) {
      item = Object.assign({}, item);
      let t = transformDice(dice);
      item.valuedice = t.valuedice;
      item.nbredice = item.nbredice ? item.nbredice : t.nbredice;
    }

    item.titre = what;
    item.value_what = parseInt(value_what);

    //récup le random
    item.result_final = 0;
    item.allThrow = [];
    for (let index = 0; index < item.nbredice; index++) {
      let th = Math.floor(Math.random() * (item.valuedice + 1 - 1) + 1);
      item.allThrow.push(th);
      item.result_final += th;
    }
    //on rajoute le bonus
    if (item.ajout) {
      item.result_final += item.ajout;
    }

    item.text = prepareText(item);

    //ajoute au state
    let arr = AllThrowPrepared;
    arr.push(item);
    setAllThrowPrepared(arr);
  }

  function deleteDices() {
    setDicesToThrow({});
    setAllThrowPrepared([]);
  }

  function handleClick() {
    setDicerOpen(false);
    setTimeout(() => {
      deleteDices();
    }, 500);
  }

  function transformBonus(bonus = false, dice = 20, value_what = 1) {
    if (bonus) {
      //Nombre de dé jeté
      let nbredice = 0;

      //Type du dé
      let valuedice = false;

      //Symbole pour le bonus
      let symbole = false;

      //Bonus post symbole
      let ajout = 0;

      let r = new RegExp(
        /^(([0-9]{1,}){1,}d{1,}([0-9]{1,}){1,}){0,}([+*-])?([0-9|a-zA-Z]{1,})?/,
      );
      let elements = r.exec(bonus);

      if (!elements) {
        return {
          nbredice: nbredice ?? false,
          valuedice: valuedice ?? false,
          symbole: symbole ?? false,
          ajout: ajout ?? false,
        };
      }
      // 0 : total
      // 1d8 : chiffre + D + chiffre
      // 2 : avant D
      nbredice = parseInt(elements[2]);
      if (isNaN(nbredice)) {
        nbredice = false;
      }
      // 3 : après D
      valuedice = parseInt(elements[3]);
      if (isNaN(valuedice)) {
        valuedice = false;
      }
      //4 : symbole
      symbole = elements[4];
      //5 : bonus
      ajout = elements[5];

      switch (ajout) {
        case 'value':
          ajout = parseInt(value_what);
          break;
        case 'dice':
          ajout = parseInt(dice);
          break;
      }

      switch (symbole) {
        case '+':
          ajout = '+' + ajout;
          ajout = parseInt(ajout);
          break;
        case '-':
          ajout = '-' + ajout;
          ajout = parseInt(ajout);
          break;
        case '*':
          if (ajout === 'dice') {
            nbredice = parseInt(value_what);
            ajout = false;
          }
      }

      bonus = {
        nbredice: nbredice ?? false,
        valuedice: valuedice ?? false,
        symbole: symbole ?? false,
        ajout: ajout ?? false,
      };
    }
    return bonus;
  }

  function transformDice(value) {
    let reg = new RegExp('^([0-9]?)d([0-9]{1,})');
    let r = reg.exec(value);
    return { valuedice: parseInt(r[2]), nbredice: parseInt(r[1]) };
  }

  function recupVariable(value, dice = 20, value_what = 1) {
    if (!value) {
      return value;
    }

    //[dice]
    let reg = new RegExp(/\[dice\]/, 'gim');
    value = value.replace(/\[dice\]/, dice);

    //[value]
    reg = new RegExp(/\[value\]/, 'gim');
    value = value.replace(reg, parseInt(value_what));

    return value;
  }

  function prepareText(item) {
    let str = item.titre ? item.titre + ' : ' : '';
    str += item.result_final + ' sur ' + item.valuedice;
    str += ' [';
    let c = 0;
    item.allThrow.forEach(th => {
      str += th;
      if (c < item.allThrow.length - 1) {
        str += ', ';
      }
      c++;
    });

    if (item.ajout) {
      str += ' ' + item.symbole + ' ' + item.ajout;
    }
    str += ']';

    return str;
  }

  return (
    <ThrowdicerContext.Provider
      value={{
        DicerOpen,
        setDicerOpen,
        prepareThrow,
        AllThrowPrepared,
      }}
    >
      {children}
    </ThrowdicerContext.Provider>
  );
};
