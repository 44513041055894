import React, { createContext, useState, useEffect } from 'react';

//import Connecter from '../../ajax/Connecter.class';
import Connecter from '../../ajax/Connecter_v2.class';

export const ListHerosContext = createContext();

export const ListHerosProvider = ({ children }) => {
  const [ListHeros, setListHeros] = useState([]);

  useEffect(() => {
    reloadList();
  }, []);

  async function reloadList() {
    get_list_heros().then(res => {
      setListHeros(res);
    });
  }

  /**
   * Récupère la liste des héros via API
   */
  function get_list_heros() {
    return new Promise((r, f) => {
      new Connecter('get_list_heros').connect_to_api().then(res => {
        //console.log(res);
        if (res.res) {
          if (res.res.length) {
            r(res.res);
          } else {
            r([]);
          }
        }
      });
    });
  }

  return (
    <ListHerosContext.Provider value={{ ListHeros, setListHeros, reloadList }}>
      {children}
    </ListHerosContext.Provider>
  );
};
