import React, { useState } from 'react';

import Btns from '../../Default/Btns';

import ImgCercle from '../../../../assets/bg/cercle_magic.svg';

const PopupNotes = ({ Id, Unit, Close, InClosure }) => {
  return (
    <div id={Id} className={'popupNote ' + (InClosure ? 'close' : '')}>
      <Btns Icon="close" OnClick={Close} />
      <span className="titre">{Unit.titre}</span>
      <span className="shortdesc">{Unit.short_desc}</span>
      <div className="desclong">{Unit.value}</div>
      <img src={ImgCercle} alt="" className="bgImg" />
    </div>
  );
};

export default PopupNotes;

/**
 * Pour l'ajout d'item
 */

export const PopupNotesForm = ({ Id, Unit, Close, InClosure, OnValid }) => {
  const [Titre, setTitre] = useState('');
  const [Shortdesc, setShortdesc] = useState('');
  const [Longdesc, setLongdesc] = useState('');

  function handleClick() {
    if (!Titre) {
      return;
    }

    if (OnValid) {
      OnValid({
        titre: Titre,
        short_desc: Shortdesc,
        value: Longdesc,
      });
    }
  }

  return (
    <div id={Id} className={'popupNote ' + (InClosure ? 'close' : '')}>
      <Btns Icon="close" OnClick={Close} />
      <input
        className="titre"
        type="text"
        name="titre"
        placeholder="Nom"
        value={Titre}
        onChange={e => setTitre(e.target.value)}
      />
      <input
        className="shortdesc"
        type="text"
        name="shortdesc"
        placeholder="Descrition courte"
        value={Shortdesc}
        onChange={e => setShortdesc(e.target.value)}
      />
      <textarea
        name="desclong"
        className="desclong"
        placeholder="Description longue"
        value={Longdesc}
        onChange={e => setLongdesc(e.target.value)}
      ></textarea>

      <Btns ID="BtnaddItem" Icon="add" OnClick={handleClick} Text="Ajouter" />

      <img src={ImgCercle} alt="" className="bgImg" />
    </div>
  );
};
