import React from "react";

import D2 from "../../../assets/icons/dices/dice2.svg";
import D4 from "../../../assets/icons/dices/dice4.svg";
import D6 from "../../../assets/icons/dices/dice6.svg";
import D8 from "../../../assets/icons/dices/dice8.svg";
import D10 from "../../../assets/icons/dices/dice10.svg";
import D12 from "../../../assets/icons/dices/dice12.svg";
import D20 from "../../../assets/icons/dices/dice20.svg";
import D100 from "../../../assets/icons/dices/dice100.svg";

import D4b from "../../../assets/icons/dices/dice4_bl.svg";
import D6b from "../../../assets/icons/dices/dice6_bl.svg";
import D8b from "../../../assets/icons/dices/dice8_bl.svg";
import D10b from "../../../assets/icons/dices/dice10_bl.svg";
import D12b from "../../../assets/icons/dices/dice12_bl.svg";
import D20b from "../../../assets/icons/dices/dice20_bl.svg";
import D100b from "../../../assets/icons/dices/dice100_bl.svg";

const IconDice = ({ Type, Black }) => {
       function geticon() {
              let icon;

              switch (Type) {
                     case "2":
                     case 2:
                            icon = D2;
                            break;

                     case "4":
                     case 4:
                            icon = Black ? D4b : D4;
                            break;

                     case "6":
                     case 6:
                            icon = Black ? D6b : D6;
                            break;

                     case "8":
                     case 8:
                            icon = Black ? D8b : D8;
                            break;

                     case "10":
                     case 10:
                            icon = Black ? D10b : D10;
                            break;

                     case "12":
                     case 12:
                            icon = Black ? D12b : D12;
                            break;

                     case "20":
                     case 20:
                     default:
                            icon = Black ? D20b : D20;
                            break;

                     case "100":
                     case 100:
                            icon = Black ? D100b : D100;
                            break;
              }

              return <img src={icon} alt="" />;
       }

       return <>{geticon()}</>;
};

export default IconDice;
