import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEllipsisH,
  faPen,
  faTimes,
  faDiceD20,
  faPlus,
  faHeart,
  faListUl,
  faTrashAlt,
  faSave,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';

import IcoCara from '../../../assets/icons/ico_carac.svg';
import IcoUnit from '../../../assets/icons/ico_unit.svg';

import IconDice from './IconDice';

const Btns = ({
  ClassName,
  ID,
  Icon,
  Taille,
  Detail,
  OnClick,
  Style = {},
  Text,
  Desactif,
}) => {
  const CLASS = 'alf_btn' + (Text ? ' alf_btn_text' : '');

  const default_style = {
    width: Taille,
    height: Taille,
    cursor: 'pointer',
  };

  const STYLE = Object.assign(Style, default_style);

  function handleClick(e) {
    if (Desactif) {
      return;
    }

    if (OnClick) {
      OnClick(e);
    }
  }

  function getIcon() {
    switch (Icon) {
      default:
      case 'menu':
        return <FontAwesomeIcon icon={faEllipsisH} />;

      case 'none':
        return;

      case 'modif_heros':
        return <FontAwesomeIcon icon={faPen} />;

      case 'eye':
        return <FontAwesomeIcon icon={faEye} />;

      case 'close':
        return <FontAwesomeIcon icon={faTimes} />;

      case 'add':
        return <FontAwesomeIcon icon={faPlus} />;

      case 'dice':
        return <IconDice Type={Detail} />;

      case 'dice_bl':
        return <IconDice Type={Detail} Black={true} />;

      case 'dice20':
        return <FontAwesomeIcon icon={faDiceD20} />;

      case 'heart':
        return <FontAwesomeIcon icon={faHeart} />;

      case 'list':
        return <FontAwesomeIcon icon={faListUl} />;

      case 'carac':
        return <img src={IcoCara} alt="" style={{ width: '55%' }} />;

      case 'unit':
        return <img src={IcoUnit} alt="" style={{ width: '55%' }} />;

      case 'delete':
        return <FontAwesomeIcon icon={faTrashAlt} />;

      case 'save':
        return <FontAwesomeIcon icon={faSave} />;

      case 'logout':
        return <FontAwesomeIcon icon={faSignOutAlt} />;
    }
  }

  function getClassName() {
    let res = CLASS;
    if (Desactif) {
      res = res + ' desactif';
    }

    if (ClassName) {
      res = res + ' ' + ClassName;
    }

    return res;
  }

  return (
    <div
      id={ID ?? ''}
      className={getClassName()}
      style={STYLE ?? default_style}
      onClick={handleClick}
    >
      {getIcon()}
      {Text && <span>{Text}</span>}
    </div>
  );
};

export default Btns;
