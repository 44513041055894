import React from 'react';
import { motion } from 'framer-motion';

import IconDice from '../Default/IconDice';

const Dices = ({ Type, Value, Key, t, te }) => {
  const variants = {
    hidden: {
      opacity: 0,
      x: '-100%',
      position: 'absolute',
    },
    visible: {
      opacity: 1,
      x: 0,
      position: 'relative',
    },
  };
  return (
    <motion.div
      data-t={Key}
      className="dicethrow"
      animate="visible"
      initial="hidden"
      variants={variants}
      transition={{
        ease: 'easeInOut',
        delay: Key * 0.9,
      }}
    >
      <span>{Value}</span>
      <IconDice Type={Type} />
    </motion.div>
  );
};

export default Dices;
