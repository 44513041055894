import React from 'react';

import Logo from '../Default/Logo';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className="inside">
        <div className="col">
          <Logo />
        </div>
        <div className="col">
          <h3>L'application</h3>
          <Link to="/app">Essayer</Link>
          <Link to="/help">Explication des formules</Link>
        </div>
        <div className="col">
          <h3>A propos</h3>
          <a href="https://kevin-soulhol.fr/">En savoir plus sur le créateur</a>
          <a href="https://kevin-soulhol.fr/creations/Parcours/index.html">
            Connaître son parcours
          </a>
          <a href="http://www.tinybattleroyale.kevin-soulhol.fr/">
            Essayez Tiny battle Royale
          </a>
        </div>
        <div className="col">
          <h3>Chartes</h3>
          <a href="#">Conditions générales</a>
          <a href="#">Paramètres des cookies</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
