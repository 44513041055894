import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { getNameBloc } from '../../../Functions/functions';
import DicesData from '../../../Data/Dices.json';

import BtnLightOnCarac from './Modules/BtnLightOnCarac';
import Uniter from './Modules/Uniter';
import PopupNotes, { PopupNotesForm } from './Modules/PopupNotes';

import ImgTitre from '../../../assets/bg/fioriture_titre.svg';
import ImgTitre2 from '../../../assets/bg/fioritures2.svg';
import Btns from '../Default/Btns';

import { ThrowdicerContext } from '../../Context/Throwdicer.context';

const DisplayerBloc = ({ Bloc, ValueDice, Save_heros }) => {
  const [OpenAddItem, setOpenAddItem] = useState(false);
  const [InClosure, setInClosure] = useState(false);

  function handleClickAddUnitInList(bloc) {
    setOpenAddItem(true);
  }

  function Close() {
    setInClosure(true);
    setTimeout(() => {
      setOpenAddItem(false);
      setInClosure(false);
    }, 500);
  }

  function handleValidAddItem(item) {
    Bloc.contain.push(item);
    Close();
    Save_heros();
  }

  function getUnit(Unit, key) {
    switch (Bloc.type) {
      default:
        return (
          <Carac
            Unit={Unit}
            key={key}
            ValueDice={ValueDice}
            Save_heros={Save_heros}
          />
        );

      case 'UNIT':
        return <Uniter Unit={Unit} key={key} Save_heros={Save_heros} />;

      case 'LIST':
        return <Notes Unit={Unit} key={key} ValueDice={ValueDice} />;

      case 'LIFE':
        return (
          <Life
            Unit={Unit}
            key={key}
            ValueDice={ValueDice}
            Save_heros={Save_heros}
          />
        );
    }
  }

  return (
    <div
      className={'displayerblocs displayerblocs_' + Bloc.type}
      id={'bloc_' + Bloc.id}
    >
      {Bloc.type !== 'LIFE' && Bloc.type !== 'UNIT' && (
        <div className="enteteBloc">
          <img
            className="fioriture"
            src={Bloc.type === 'LIST' ? ImgTitre2 : ImgTitre}
            alt=""
          />
          <h4>{getNameBloc(Bloc)}</h4>
          <span className="copy">{getNameBloc(Bloc)}</span>
          <img
            className="fioriture"
            src={Bloc.type === 'LIST' ? ImgTitre2 : ImgTitre}
            alt=""
          />
        </div>
      )}

      <div className="containerElementBloc">
        <ul>
          {Bloc &&
            Array.isArray(Bloc.contain) &&
            Bloc.contain.map((unit, key) => getUnit(unit, key))}
          {Bloc.type === 'LIST' && (
            <Btns Icon="add" OnClick={e => handleClickAddUnitInList(Bloc)} />
          )}
          {Bloc.type === 'LIST' && OpenAddItem && (
            <PopupNotesForm
              Unit={false}
              InClosure={InClosure}
              Close={e => Close()}
              OnValid={handleValidAddItem}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default DisplayerBloc;

/**
 * Affiche une note d'un bloc Liste
 * @param {*} param0
 * @returns
 */
const Notes = ({ Unit, ValueDice }) => {
  const [PopUpOpen, setPopUpOpen] = useState(false);

  const [InClosure, setInClosure] = useState(false);

  function handleClickOnNote(unit) {
    setPopUpOpen(PopUpOpen ? false : true);
  }

  function Close() {
    setInClosure(true);
    setTimeout(() => {
      setPopUpOpen(false);
      setInClosure(false);
    }, 500);
  }

  return (
    <>
      <li
        className="elementInNote elementBloc"
        onClick={e => handleClickOnNote(Unit)}
      >
        <div className="texts">
          <span className="titre">{Unit.titre}</span>
          <span className="shortdesc">{Unit.short_desc}</span>
        </div>
        <Btns Icon="eye" />
      </li>
      {PopUpOpen && (
        <PopupNotes Unit={Unit} InClosure={InClosure} Close={e => Close()} />
      )}
    </>
  );
};

/**
 * Affiche une caractéristique ou une compétence
 * @param {*} param0
 * @returns
 */
const Carac = ({ Unit, ValueDice, Save_heros }) => {
  const { prepareThrow, setDicerOpen } = useContext(ThrowdicerContext);

  function handleClickOnLight() {
    Unit.selected = Unit.selected ? false : true;
    Save_heros();
  }

  return (
    <li className="elementBloc">
      <BtnLightOnCarac
        DefaultActif={Unit.selected}
        OnClick={handleClickOnLight}
      />
      <span className="titre">{Unit.titre}</span>
      {Unit.dice && <span className="dice">({Unit.dice})</span>}
      <span className="value">{Unit.value ?? Unit.chiffre}</span>
      <Btns
        Icon="dice20"
        Taille="40px"
        OnClick={e => {
          prepareThrow(
            Unit.titre,
            DicesData.dices[ValueDice === '' || !ValueDice ? '20' : ValueDice]
              .name,
            Unit.dice,
            Unit.value ?? Unit.chiffre,
          );
          setDicerOpen(true);
        }}
      />
    </li>
  );
};

/**
 * Affiche une Unit
 * @param {*} param0
 * @returns
 */
const Life = ({ Unit, Save_heros }) => {
  const [Value, setValue] = useState(Unit.current);

  function handleChange(e) {
    setValue(e.target.value);
    Unit.current = e.target.value;
    Save_heros();
  }

  return (
    <div className="BlocLife">
      <FontAwesomeIcon
        icon={faHeart}
        style={{ color: Unit.color ?? Unit.color }}
      />
      <div className="firstLine">
        <span className="currentLife">{Value}</span>
        <input
          className="current_life_bar"
          type="range"
          name="current_life"
          max={Unit.value}
          min="0"
          value={Value}
          onChange={e => handleChange(e)}
          style={{ background: Unit.color ?? Unit.color }}
        />

        <span className="maxLife">{Unit.value}</span>
      </div>
    </div>
  );
};
