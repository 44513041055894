import React, { useState, useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import { HerosContext } from '../../Context/SelectedHeros';
import { ThrowdicerContext } from '../../Context/Throwdicer.context';

import Btns from '../Default/Btns';
import SelectDices from '../ThrowDices/SelectDices.js';

import { handleAddBloc } from '../../../Functions/formModifHeros.func';

const Menu = ({ inModifier, setInModifier }) => {
  const { heros, setBlocs, submitFormModifHeros } = useContext(HerosContext);
  const { setDicerOpen, ReadyToThrow, prepareThrow } =
    useContext(ThrowdicerContext);

  //Si le menu de selection des dé est ouvert
  const [Opened, setOpened] = useState(false);

  const [DicesToThrow, setDicesToThrow] = useState({});

  const [onClick, setOnClick] = useState(false);

  useEffect(() => {
    const close = () => {
      setOpened(false);
      setDicesToThrow({});
    };

    if (Opened) {
      document.querySelector('main').addEventListener('click', close, false);
    }

    return () => {
      if (Opened) {
        document
          .querySelector('main')
          .removeEventListener('click', close, false);
      }
    };
  }, [Opened]);

  /** IN SELECTER DICE */
  function handleClick(e) {
    //si on est prêt à jeter
    if (Object.keys(DicesToThrow).length) {
      setDicesToThrow(false);
      setDicesToThrow({});
      for (const key in DicesToThrow) {
        prepareThrow(false, DicesToThrow[key] + 'd' + key);
      }
      setDicerOpen(true);

      ReactGA.event({
        category: 'User',
        action: 'jette-dices',
      });
    }

    //dans tous les cas on ouvre ou ferme le dicer
    setOpened(Opened ? false : true);
  }

  function addDice(dice, number = false) {
    if (onClick) {
      return;
    }

    setOnClick(true);
    let dices = Object.assign({}, DicesToThrow);
    let ajout = number ? number : 1;

    if (!dices[dice.id]) {
      dices[dice.id] = 0;
    }
    dices[dice.id] = dices[dice.id] + ajout;

    setDicesToThrow(dices);
    setTimeout(() => {
      setOnClick(false);
    }, 100);
  }

  /** IN MODIF HEROS */
  function saveForm() {
    submitFormModifHeros();
    setInModifier(false);
  }

  return (
    <div id="container_menu">
      <div id="menu">
        {inModifier && (
          <div className="containerInModifier">
            <Btns
              ID="add_life"
              Icon="heart"
              OnClick={e => handleAddBloc(heros, 'LIFE', setBlocs)}
            />
            <Btns
              ID="add_list"
              Icon="list"
              OnClick={e => handleAddBloc(heros, 'LIST', setBlocs)}
            />
            <Btns
              ID="add_unit"
              Icon="unit"
              OnClick={e => handleAddBloc(heros, 'UNIT', setBlocs)}
            />
            <Btns
              ID="add_cara"
              Icon="carac"
              OnClick={e => handleAddBloc(heros, 'CARA', setBlocs)}
            />
            <Btns ID="send_form" Icon="save" Taille="65px" OnClick={saveForm} />
          </div>
        )}

        {!inModifier && (
          <div className="containerthrowdice">
            <Btns
              ID="throw_dice"
              ClassName={ReadyToThrow ? 'ready' : ''}
              Icon="dice20"
              Taille="65px"
              OnClick={e => handleClick(e)}
            />
            <SelectDices
              Opened={Opened}
              addDice={addDice}
              DicesToThrow={DicesToThrow}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
