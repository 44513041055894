import React, { useState } from "react";

const BtnLightOnCarac = ({ DefaultActif, OnClick }) => {
       const [Actif, setActif] = useState(DefaultActif ?? false);

       function handleClick() {
              setActif(Actif ? false : true);

              if (OnClick) {
                     OnClick();
              }
       }

       return (
              <div className="containerSelectedBtn" onClick={handleClick}>
                     <div
                            className={
                                   Actif ? "selectedBtn actif" : "selectedBtn"
                            }
                     ></div>
              </div>
       );
};

export default BtnLightOnCarac;
