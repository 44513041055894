import React from 'react';

const Breadcrumb = ({ Current, Total, ClickOnPoint }) => {
  function getPoints() {
    let list = [];
    for (let i = 0; i < Total + 1; i++) {
      list.push(
        <div
          key={i}
          className={i === Current ? 'pf_breadcrumb_active' : 'pf_breadcrumb'}
          onClick={e => ClickOnPoint(i)}
        >
          <div className="pf_point"></div>
        </div>,
      );
    }
    return list;
  }

  return <div className="pf_contain_breadcrum">{getPoints()}</div>;
};

export default Breadcrumb;
