import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Home from './Components/Pages/Home';
import Login from './Components/Pages/Login';
import Help from './Components/Pages/Help';
import reportWebVitals from './reportWebVitals';

import ReactGA from 'react-ga';

ReactGA.initialize('G-28H6HJ6BMG');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.event({
  category: 'User',
  action: 'Se connecte',
});

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/app" element={<App />} />
      <Route path="/help" element={<Help />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//<Route path="/deco" element={<Login Deco={true} />} />
