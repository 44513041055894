import React, { useEffect, useState } from 'react';
import ReactMarkDown from 'react-markdown';

import Header from '../Elements/Home/Header';
import Footer from '../Elements/Home/Footer';

import helpMd from '../../Data/expliqueFormules.md';

const Help = () => {
  const [contain, setContain] = useState('');

  useEffect(() => {
    fetch(helpMd)
      .then(res => res.text())
      .then(text => setContain(text));
  });

  return (
    <main id="help" className="page-site">
      <Header />
      <section>
        <div className="inside">
          <ReactMarkDown children={contain} />
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Help;
