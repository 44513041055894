import React from "react";

import { motion, AnimatePresence } from "framer-motion";

import DicesData from "../../../Data/Dices.json";
import Btns from "../Default/Btns";

import { return_map } from "../../../Functions/functions";

const SelectDices = ({ Opened, addDice, DicesToThrow }) => {
       const variantsParent = {
              initial: {
                     x: 0,
                     transition: { staggerChildren: 7, delayChildren: 2 },
              },
              hidden: {
                     x: "200%",
                     transition: { staggerChildren: 7, delayChildren: 2 },
              },
       };

       return (
              <AnimatePresence>
                     {Opened && (
                            <motion.div
                                   className="selecterDice"
                                   initial="hidden"
                                   animate="initial"
                                   exit="hidden"
                                   variants={variantsParent}
                            >
                                   {DicesData &&
                                          return_map(DicesData.dices).map(
                                                 (dice, key) => (
                                                        <motion.div
                                                               className="containerDice"
                                                               id={
                                                                      "dice_" +
                                                                      dice.id
                                                               }
                                                               key={key}
                                                               onClick={(e) =>
                                                                      addDice(
                                                                             dice,
                                                                      )
                                                               }
                                                               initial="hidden"
                                                               animate="initial"
                                                               whileTap={{
                                                                      scale: 1.1,
                                                               }}
                                                        >
                                                               {DicesToThrow[
                                                                      dice.id
                                                               ] && (
                                                                      <div className="nbreselected">
                                                                             {
                                                                                    DicesToThrow[
                                                                                           dice
                                                                                                  .id
                                                                                    ]
                                                                             }
                                                                      </div>
                                                               )}

                                                               <Btns
                                                                      ClassName="dice"
                                                                      Icon="dice_bl"
                                                                      Detail={
                                                                             dice.id
                                                                      }
                                                               />
                                                               <div className="typeDice">
                                                                      {dice.id}
                                                               </div>
                                                        </motion.div>
                                                 ),
                                          )}
                            </motion.div>
                     )}
              </AnimatePresence>
       );
};

export default SelectDices;
