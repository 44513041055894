import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { HerosContext } from '../Context/SelectedHeros';
import { return_map } from '../../Functions/functions';

import Dices from '../../Data/Dices.json';
import AllTexts from '../../Data/AllTexts.json';
import Templates from '../../Data/Templates.json';

import Input from './Input';
import Select from './Select';
import ModifHerosBloc from './Blocs/ModifHerosBloc';
import PageForm from './Blocs/PageForm';
import Btns from '../Elements/Default/Btns';
import Breadcrumb from './Breadcrumb';
import { useNavigate } from 'react-router-dom';

const FormModifHeros = ({ setInModifier }) => {
  const { heros, setHeros, modifHeros, blocs, setBlocs, submitFormModifHeros } =
    useContext(HerosContext);
  let navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [textBtn, setTextBtn] = useState('Continuer');
  const [desactiveBtn, setDesactiveBtn] = useState(false);

  useEffect(() => {
    setTotalPage(Array.from(document.querySelectorAll('.page')).length - 1);
    //handleChangeTemplate('creation');
  }, [blocs]);

  useEffect(() => {
    if (currentPage === totalPage) {
      setIsLastPage(true);
      setTextBtn('Ajouter');
    } else {
      setIsLastPage(false);
      setTextBtn('Continuer');
    }
  }, [currentPage, totalPage]);

  /**
   * Desactive le bouton "Continuer" sur certaines pages du form
   */
  useEffect(() => {
    switch (currentPage) {
      case 0:
        if (!heros.nom) {
          setDesactiveBtn(true);
        }
        break;
      case 1:
        if (!heros.profession) {
          setDesactiveBtn(true);
        }
        break;
      case 2:
      case 4:
        setTextBtn('Passer');
      default:
        break;
    }
  }, [currentPage]);

  /**
   * Supprime le bloc demandé
   * @param {string | int} id sa clef dans le tbaleau Selected.blocs
   */
  function handleDeleteBloc(id) {
    let arr = [...blocs];
    arr.splice(id, 1);

    setBlocs(arr);
  }

  //Adapte le form selon le template sélectionné
  function handleChangeTemplate(template_id) {
    let selected_template = Templates[template_id];
    let newhero = {
      dice_to_roll: selected_template.dice_to_roll,
      profession: selected_template.profession,
      blocs: selected_template.blocs,
    };

    setHeros(newhero);
    setBlocs(newhero.blocs);
  }

  function handleAdd() {
    submitFormModifHeros().then(res => {
      if (res) {
        navigate('/app');
      }
    });
  }

  function handleNext() {
    if (!isLastPage) {
      goTo(currentPage + 1);
    } else {
      handleAdd();
    }
  }

  function handlePrev() {
    goTo(currentPage - 1);
  }

  function goTo(numeroPage) {
    if (numeroPage < 0) {
      numeroPage = 0;
    }
    if (numeroPage > totalPage) {
      numeroPage = totalPage;
    }
    setCurrentPage(numeroPage);
  }

  function handleChangeInputRequired(e) {
    if (e.target.value !== '') {
      setDesactiveBtn(false);
    } else {
      setDesactiveBtn(true);
    }
  }

  return (
    <form
      id="form_modif_heros"
      className="form_create"
      onSubmit={e => e.preventDefault()}
    >
      <Btns
        Icon="close"
        ID="closeBtn"
        OnClick={e => {
          setInModifier(false);
          modifHeros('last');
        }}
      />

      <motion.div
        className="scrollerForm"
        animate={{ y: '-' + currentPage * 100 + 'vh' }}
      >
        <PageForm
          H2="Créer votre héros"
          Desc="Vous allez créer une nouvelle fiche personnage"
          Label="Quel est le nom de votre personnage ?"
        >
          <Input
            Type="text"
            Name="nom"
            Placeholder={AllTexts.inputs.herosname}
            Label="Quel est son nom ?"
            DefaultValue={heros.nom}
            Required={true}
            onChange={handleChangeInputRequired}
          />
        </PageForm>

        <PageForm
          H2="Créer votre héros"
          Desc="A titre indicatif, cet élément vous permet de vous rappeler sa profession, sa classe, sa race ou n'importe quel élément correspondant à l'univers dans lequel vous jouez"
          Label="Quel est sa profession ou sa classe ?"
        >
          <Input
            Type="text"
            Name="profession"
            Placeholder={AllTexts.inputs.herosprofession}
            DefaultValue={heros.profession}
            Required={true}
            onChange={handleChangeInputRequired}
          />
        </PageForm>

        <PageForm
          H2="Créer votre héros"
          Desc="Vous pouvez ajouter une image à votre héros. Collez ici le lien lui correspondant."
          Label="Entrez l'url de l'image :"
        >
          <Input Type="text" Name="img" DefaultValue={heros.img} />
        </PageForm>

        <PageForm
          H2="Créer votre héros"
          Desc="Pour la fiche de votre héros, voulez-vous utiliser un template existant ou créer de toute pièce ?"
          //Label="Quel template de fiche utiliser ?"
        >
          <select
            name="Template"
            id=""
            onChange={e => handleChangeTemplate(e.target.value)}
            defaultValue="creation"
          >
            {return_map(Templates).map((opt, key) => (
              <option value={opt.id} key={key}>
                {opt.name}
              </option>
            ))}
          </select>

          <Select
            Name="dice_to_roll"
            Options={Dices.dices}
            DefaultSelect={
              heros.dice_to_roll === '' || !heros.dice_to_roll
                ? '20'
                : heros.dice_to_roll
            }
            Required={true}
            Label={AllTexts.inputs.dicetoroll}
          />
        </PageForm>

        {blocs &&
          blocs.map((bloc, key) => (
            <PageForm H2={getH2(bloc.type)} Desc={getDesc(bloc.type)} key={key}>
              <ModifHerosBloc
                Bloc={bloc}
                key={key}
                Key={key}
                handleDeleteBloc={handleDeleteBloc}
              />
            </PageForm>
          ))}
      </motion.div>

      <div className="containNav">
        <Btns
          Icon="none"
          Text={textBtn}
          Style={{ minWidth: '100%' }}
          OnClick={handleNext}
          Desactif={desactiveBtn}
        />
        <Breadcrumb
          Current={currentPage}
          Total={totalPage}
          ClickOnPoint={goTo}
        />
      </div>
    </form>
  );
};

export default FormModifHeros;

function getH2(type) {
  switch (type) {
    case 'CARA':
      return 'Définissez vos caractéristiques';
    case 'COMP':
      return 'Définissez vos compétences';
    case 'LIFE':
      return 'Combien de points de vie';
    case 'UNIT':
      return 'Ajoutez des blocs particuliers';
    default:
      return '';
  }
}

function getDesc(type) {
  switch (type) {
    case 'CARA':
      return 'Force, Dextérité, Chance, … indiquez les caractéristiques nécessaires à votre héros';
    case 'COMP':
      return 'Acrobatie, athlétisme, armes de poing, …. choisissez vos compétences et leur niveau';
    case 'LIFE':
      return 'Indiquez le nombre de points de vie maximum que votre héros possède.';
    case 'UNIT':
      return 'Si nécessaire, vous pouvez ajouter ici quelques éléments supplémentaires à votre héros comme son niveau ou sa vitesse.';
    default:
      return '';
  }
}
