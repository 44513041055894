import React, { useContext } from 'react';
import { HerosContext } from '../../Context/SelectedHeros';

import DisplayerBloc from './DisplayerBloc';
import IconDice from '../Default/IconDice';

import DefaultImg from '../../../assets/default_avatar.jpg';

const DisplaySelectedHeros = () => {
  const { heros, update_heros } = useContext(HerosContext);

  function saveChangeHeros() {
    update_heros({ id: heros.id, blocs: heros.blocs });
  }

  return (
    <>
      {Object.keys(heros).length !== 0 && (
        <div className="displayerHeros">
          <div className="displayHero">
            <div className="containerEnteteHeros">
              <div className="containImgHeros">
                <img
                  src={
                    !heros.img || heros.img === undefined
                      ? DefaultImg
                      : heros.img
                  }
                  alt="Avatar du Heros"
                />
              </div>
              <div className="containerDiceforHeros">
                <IconDice Type={heros.dice_to_roll} />
                <span>{heros.dice_to_roll}</span>
              </div>
              <h1>{heros.nom}</h1>
              <h2>{heros.profession ?? ''}</h2>
            </div>

            <div className="containerBlocs">
              {heros.blocs &&
                heros.blocs.map((bloc, key) => (
                  <DisplayerBloc
                    key={key}
                    Bloc={bloc}
                    ValueDice={heros.dice_to_roll}
                    Save_heros={saveChangeHeros}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DisplaySelectedHeros;
