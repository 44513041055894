/**
 * Retourne le nom du Bloc selon son type
 * @param {obj} Bloc object Bloc
 * @returns
 */
export function getNameBloc(Bloc) {
  let name = false;
  if (Bloc.name === '' || !Bloc.name || Bloc.name === undefined) {
    name = false;
  } else {
    name = Bloc.name;
  }

  switch (Bloc.type) {
    default:
      return name ? name : '';
    case 'CARA':
      return name ? name : 'Mes caractéristiques';

    case 'COMP':
      return name ? name : 'Mes compétences';

    case 'LIFE':
      return name ? name : 'Ma vie';
  }
}

/**
 * Lance les dés et les enregistre dans sessionStorage(dices)
 *
 *
 * @param {string} what
 * @param {string} dice 1d20 | 3d100
 * @param {int | string} bonus peut être négatif (+1 | -4 | *2 | *dice (jette un nbre de dé égal à l'attribut de la valeur value_what))
 * @param {int | string} value_what correspond à la valeur de la caractéristique
 * @returns le résultat final
 */
export function throw_dice(what, dice, bonus = false, value_what = false) {
  let final = 0;
  let rand = [];

  let nbredice = 1;
  let valuedice = false;

  let dontchoosedice = false;

  //preparation du bonus
  if (bonus) {
    let reg = new RegExp('^([+*-])([0-9]+|dice)$');
    let t = reg.exec(bonus);
    if (t) {
      bonus = {
        nbre: t[2] === 'dice' ? 'dice' : parseInt(t[2]),
        formule: t[1],
      };
    } else {
      reg = new RegExp('^([0-9]+)d([0-9]+)(([+*-])([0-9]+))?');
      t = reg.exec(bonus);
      if (t) {
        valuedice = parseInt(t[2]);
        nbredice = parseInt(t[1]);
        dontchoosedice = true;
        if (t[3] && t[3] !== undefined) {
          bonus = {
            nbre: t[5] === 'dice' ? 'dice' : parseInt(t[5]),
            formule: t[4],
          };
        } else {
          bonus = false;
        }
      } else {
        bonus = false;
      }
    }
  }

  //transforme le dice
  if (!dontchoosedice) {
    if (!Number.isInteger(dice)) {
      dice = dice.split('d');
      nbredice = parseInt(dice[0]);
      valuedice = parseInt(dice[1]);

      if (bonus.nbre === 'dice') {
        nbredice = parseInt(value_what);
        bonus = false;
      }
    } else {
      valuedice = dice;
    }
  }

  for (let index = 0; index < nbredice; index++) {
    let n = Math.floor(Math.random() * (valuedice + 1 - 1) + 1);
    rand.push(n);
    final += parseInt(n);
    if (bonus.formule === '*') {
      final = final * parseInt(bonus.nbre);
    }
  }

  if (bonus.formule !== '*') {
    switch (bonus.formule) {
      case '+':
        final = final + parseInt(bonus.nbre);
        break;
      case '-':
        final = final - parseInt(bonus.nbre);
        break;
      default:
        break;
    }
  }

  //creation phrase
  let str = '';
  if (what) {
    str = what + ' : ';
  }

  str += final;
  str = str + ' sur ' + valuedice;

  str += ' [';
  rand.forEach((element, key) => {
    str += element;
    if (key < rand.length - 1) {
      str += ', ';
    }
  });

  if (bonus) {
    str += ' ' + bonus.formule + ' ' + bonus.nbre;
  }
  str += '] ';

  //enregistre la session
  let dices = [];
  let sess_dices;
  if ((sess_dices = sessionStorage.getItem('dices'))) {
    sess_dices = sess_dices.split(';');
    dices = sess_dices;
  }

  //empêche de dépasser 5 elements dans la session
  if (dices.length > 5) {
    dices.shift();
  }

  dices.push(str);
  dices = dices.join(';');
  sessionStorage.setItem('dices', dices);

  return {
    value: final,
    text: str,
    dice: valuedice,
    bonus: bonus,
    throw: rand,
  };
}

/** Transforme  un objet en array pour fonctionner dans un map() */
export function return_map(obj) {
  let result = [];
  for (const key in obj) {
    result.push(obj[key]);
  }

  return result;
}

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
export function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}

/**
 * Return le chemin selon qu'on soit en local ou en ligne
 * @param {*} namefile
 * @returns
 */
export function getUrl(pathfile) {
  let reg = new RegExp('^http://localhost:3000/');
  if (reg.exec(document.URL)) {
    return 'http://alfred-v4/' + pathfile;
  } else {
    return './' + pathfile;
  }
}
