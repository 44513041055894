import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Input = ({
  Type,
  Name,
  Placeholder,
  DefaultValue,
  onChange,
  Key,
  Required,
}) => {
  const [Value, setValue] = useState('');
  const [PasswordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (DefaultValue === undefined) {
      setValue('');
    } else {
      setValue(DefaultValue);
    }
  }, [DefaultValue]);

  function handleChange(e) {
    setValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  }

  function handleClickEyePassword() {
    setPasswordVisible(prev => (prev ? false : true));
  }

  return (
    <label htmlFor={Name} className="labelForInput">
      <span>{Placeholder}</span>
      {Type === 'text' && (
        <input
          type={Type}
          name={Name}
          data-id={Key}
          value={Value}
          onChange={e => handleChange(e)}
          required={Required}
          onInvalid={e => e.preventDefault()}
          autoComplete="off"
        />
      )}
      {Type === 'password' && (
        <>
          <input
            type={PasswordVisible ? 'text' : 'password'}
            name={Name}
            data-id={Key}
            value={Value}
            onChange={e => handleChange(e)}
            required={Required}
            onInvalid={e => e.preventDefault()}
            autoComplete="off"
          />
          <FontAwesomeIcon
            icon={faEye}
            onClick={handleClickEyePassword}
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
      {Type === 'textarea' && (
        <textarea
          name={Name}
          data-id={Key}
          placeholder={Placeholder}
          value={Value}
          onChange={e => handleChange(e)}
          required={Required}
        ></textarea>
      )}
    </label>
  );
};

export default Input;
