import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Btns from '../Default/Btns';

const Popup = ({
  name,
  children,
  titre,
  texte,
  btn_confirm,
  btn_annul,
  OnValid,
  visible,
}) => {
  const [seen, setSeen] = useState(visible ?? true);

  useEffect(() => {
    setSeen(visible);
  }, [visible]);

  const variants = {
    visibled: {
      y: 0,
    },
    hidden: {
      y: 1000,
    },
  };

  function handleClose() {
    console.log('close');
    setSeen(false);
  }

  function handleValid() {
    setSeen(false);

    if (OnValid) {
      OnValid();
    }
  }

  return (
    <motion.div
      className="container-popup"
      initial={false}
      animate={seen ? 'visibled' : 'hidden'}
      variants={variants}
    >
      <div className="popup">
        <Btns
          ClassName={'close_popup close_popup_' + name}
          Icon="close"
          Style={{ background: 'none', color: 'white' }}
          OnClick={handleClose}
        />
        <div className="inside-popup">
          {children ?? (
            <>
              <h3>{titre}</h3>
              <div className="contain-text">{texte}</div>
            </>
          )}

          {btn_annul ||
            (btn_confirm && (
              <div className="container-btns">
                {btn_annul && (
                  <Btns
                    ClassName={'annul_popup annul_popup_' + name}
                    Icon="none"
                    Text={btn_annul}
                    OnClick={handleClose}
                  />
                )}

                {btn_confirm && (
                  <Btns
                    ClassName={'confirm_popup confirm_popup_' + name}
                    Icon="none"
                    Text={btn_confirm}
                    OnClick={handleValid}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Popup;
