import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useContext,
} from 'react';
import { motion } from 'framer-motion';

import { NavContext } from '../../../Context/Nav.context';

const Uniter = ({ Unit, Save_heros }) => {
  //en miliseconde (plus proche de 100 plus rapide)
  const SPEED = 10;
  const DelayLongPress = 700;

  const { PosMouse, InTouch, setInTouch } = useContext(NavContext);

  const [canModifie, setcanModifie] = useState(false);

  const [currentValue, setCurrentValue] = useState(Unit.current);
  const [startValue, setStartValue] = useState(Unit.current);
  const [MAX] = useState(Unit.value === '' || !Unit.value ? 1000 : Unit.value);

  const [startPosMouse, setStartPosMouse] = useState(PosMouse);

  const [timeOnClick, settimeOnClick] = useState(0);
  const [myInterval, setMyInterval] = useState();

  const longPressEvent = useLongPress(
    OnLongPress,
    handleStartClick,
    settimeOnClick,
    {
      delay: DelayLongPress,
    },
  );

  const variants = {
    hover: {
      y: '-10px',
    },
    tap: {
      scale: 0.9,
    },
  };

  useEffect(() => {
    if (canModifie) {
      EndLongPress(false);
    }
  }, [InTouch]);

  useEffect(() => {
    if (canModifie) {
      getNewValue(PosMouse);
    }
  }, [PosMouse]);

  useEffect(() => {
    if (timeOnClick === 0) {
      clearInterval(myInterval);
    }
  }, [timeOnClick]);

  function OnLongPress() {
    setInTouch(true);
    setcanModifie(true);
    setStartPosMouse(PosMouse);

    document.querySelector('body').style.overflowY = 'hidden';
    document.querySelector('body').style.overscrollBehaviorY = 'none';
  }

  function EndLongPress() {
    settimeOnClick(0);
    setcanModifie(false);
    setStartValue(currentValue);
    Unit.current = currentValue;
    document.querySelector('body').style.overflowY = 'initial';
    document.querySelector('body').style.overscrollBehaviorY = 'initial';
    Save_heros();
  }

  function getNewValue() {
    let reg = new RegExp('^[0-9]*$');
    if (!reg.exec(startValue)) {
      return currentValue;
    }

    if (!Object.keys(startPosMouse).length) {
      setStartPosMouse(PosMouse);
    }

    let max = MAX;
    let min = 0;

    let percent =
      ((parseInt(PosMouse.top) - parseInt(startPosMouse.top)) * 100) /
      (((SPEED * max) / 150) * parseInt(window.innerHeight));

    let newvalue = Math.ceil((percent / 100) * max);
    newvalue = parseInt(startValue) + parseInt(newvalue);

    if (newvalue < min) {
      newvalue = min;
    } else if (newvalue > max) {
      newvalue = max;
    }

    if (isNaN(newvalue) || !newvalue) {
      newvalue = 0;
    }

    setCurrentValue(parseInt(newvalue));
  }

  function handleStartClick() {
    let myinterval = setInterval(() => {
      let percent = (DelayLongPress + 500) / 110;
      settimeOnClick(prev => prev + percent);
    }, 50);
    setMyInterval(myinterval);
  }

  return (
    <motion.div
      className={canModifie ? 'slider actif' : 'slider'}
      {...longPressEvent}
      style={{
        userSelect: 'none',
        cursor: 'pointer',
      }}
      variants={variants}
      whileHover="hover"
      whileTap="tap"
    >
      <motion.div
        className="bg_uniter"
        animate={{ height: timeOnClick + '%' }}
      ></motion.div>
      <span className="titre">{Unit.titre}</span>
      <div className="containervalue">
        <span className="value">{currentValue}</span>
      </div>

      {Unit.value && <span className="max">/ {Unit.value}</span>}
    </motion.div>
  );
};

export default Uniter;

const useLongPress = (
  onLongPress,
  onClick,
  settimeOnClick,
  { shouldPreventDefault = true, delay = 300 } = {},
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    event => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      onClick();

      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault, onClick],
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      //shouldTriggerClick && !longPressTriggered && onClick();
      setLongPressTriggered(false);

      settimeOnClick(0);

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, settimeOnClick, longPressTriggered],
  );

  return {
    onMouseDown: e => start(e),
    onTouchStart: e => start(e),
    onMouseUp: e => clear(e),
    onTouchEnd: e => clear(e),
  };
};

const isTouchEvent = event => {
  return 'touches' in event;
};

const preventDefault = event => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};
