import React, { useState } from 'react';

import { getNameBloc } from '../../../Functions/functions';

import ContainBlocData from '../../../Data/ContainBloc.json';
import AllTexts from '../../../Data/AllTexts.json';

import Input from '../Input';
import Btns from '../../Elements/Default/Btns';

const ModifHerosBloc = ({ Bloc, Key, handleDeleteBloc }) => {
  const [Reload, setReload] = useState(0);
  /**
   * Récupère les éléments de l'input et les enregistre dans le bloc du heros
   * @param {event} event
   * @param {object} bloc
   */
  function traitement_bloc(event, bloc, key = 0) {
    bloc.contain[key][event.target.name] = event.target.value;
  }

  function handleAddUnit() {
    let newitem = Object.assign({}, ContainBlocData[Bloc.type]);

    Bloc.contain.push(newitem);
    setReload(Reload + 1);
  }

  function handleDeleteUnit(id) {
    Bloc.contain.splice(parseInt(id), 1);

    if (Bloc.contain.length === 0) {
      handleDeleteBloc(Key);
    }
    setReload(Reload + 1);
  }

  function handleChangeName(event) {
    Bloc.name = event.target.value;
  }

  function getModifBloc(bloc) {
    switch (bloc.type) {
      case 'LIFE':
        return (
          <ModifBlocLife
            Bloc={bloc}
            Contain={bloc.contain}
            handleDeleteBloc={e => handleDeleteBloc(Key)}
            Change={traitement_bloc}
          />
        );
      case 'UNIT':
        return (
          <ModifBlocUnit
            data-id={bloc.id}
            Bloc={bloc}
            Contain={bloc.contain}
            handleDeleteBloc={e => handleDeleteBloc(Key)}
            handleAddUnit={handleAddUnit}
            handleDeleteUnit={handleDeleteUnit}
            Change={traitement_bloc}
          />
        );
      case 'LIST':
        return (
          <ModifBlocList
            data-id={bloc.id}
            Bloc={bloc}
            Contain={bloc.contain}
            handleDeleteBloc={e => handleDeleteBloc(Key)}
            handleAddUnit={handleAddUnit}
            handleDeleteUnit={handleDeleteUnit}
            Change={traitement_bloc}
            ChangeName={handleChangeName}
          />
        );
      default:
        return (
          <ModifBlocDefault
            data-id={bloc.id}
            Bloc={bloc}
            Contain={bloc.contain}
            handleDeleteBloc={e => handleDeleteBloc(Key)}
            handleAddUnit={handleAddUnit}
            handleDeleteUnit={handleDeleteUnit}
            Change={traitement_bloc}
            ChangeName={handleChangeName}
          />
        );
    }
  }

  return (
    <>
      {Bloc && (
        <div
          className={`Bloc Bloc_${Bloc.type}`}
          id={`bloc_${Bloc.id}`}
          data-type={Bloc.type}
        >
          {getModifBloc(Bloc)}
        </div>
      )}
    </>
  );
};

export default ModifHerosBloc;

const ModifBlocLife = ({ Bloc, Contain, handleDeleteBloc, Change }) => {
  return (
    <>
      <h3>{Bloc.name !== '' ? Bloc.name : AllTexts.titres.modifBlocVie}</h3>
      <div className="btnDeleteBloc" onClick={handleDeleteBloc}>
        {AllTexts.btns.deletebloc}
      </div>
      <div className="containerInpLife">
        <Input
          Type="text"
          Name="current"
          Placeholder="Vie actuelle"
          DefaultValue={Contain[0].current}
          onChange={e => Change(e, Bloc)}
        />
        <Btns Icon="heart" />
        <Input
          Type="text"
          Name="value"
          Placeholder="Vie max"
          DefaultValue={Contain[0].value}
          onChange={e => Change(e, Bloc)}
        />
      </div>
    </>
  );
};

const ModifBlocUnit = ({
  Bloc,
  Contain,
  handleDeleteBloc,
  handleAddUnit,
  handleDeleteUnit,
  Change,
}) => {
  return (
    <>
      <h3>{AllTexts.titres.modifBlocUnit}</h3>
      <div className="btnDeleteBloc" onClick={handleDeleteBloc}>
        {AllTexts.btns.deletebloc}
      </div>
      <div className="containerInpsBtnAdd">
        <div className="containerInpUnit">
          {Contain.map((unit, key) => (
            <div className="containerModifUnit" key={key}>
              <Input
                Type="text"
                Name="titre"
                Placeholder="Nom de l'information"
                DefaultValue={unit.titre}
                onChange={e => Change(e, Bloc, key)}
              />
              <Input
                Type="text"
                Name="current"
                Placeholder="Valeur"
                DefaultValue={unit.current}
                onChange={e => Change(e, Bloc, key)}
              />
              <Input
                Type="text"
                Name="value"
                Placeholder="Max"
                DefaultValue={unit.value}
                onChange={e => Change(e, Bloc, key)}
              />
              <Btns
                Icon="delete"
                Style={{
                  background: 'transparent',
                }}
                OnClick={e => handleDeleteUnit(key)}
              />
            </div>
          ))}
        </div>
        <Btns Icon="add" OnClick={handleAddUnit} />
      </div>
    </>
  );
};

const ModifBlocDefault = ({
  Bloc,
  Contain,
  handleDeleteBloc,
  handleAddUnit,
  handleDeleteUnit,
  Change,
  ChangeName,
}) => {
  return (
    <>
      <h3>{AllTexts.titres.modifBlocDefault}</h3>
      <div className="btnDeleteBloc" onClick={handleDeleteBloc}>
        {AllTexts.btns.deletebloc}
      </div>

      <Input
        Type="text"
        Name="name"
        Placeholder="Nom du bloc"
        DefaultValue={getNameBloc(Bloc)}
        onChange={e => ChangeName(e)}
      />

      <div className="containerInpsBtnAdd">
        <div className="containerInpDefault">
          {Contain.map((unit, key) => (
            <div className="containerModifUnit" key={key}>
              <Input
                Type="text"
                Name="titre"
                Placeholder="Nom de l'information"
                DefaultValue={unit.titre}
                onChange={e => Change(e, Bloc, key)}
              />
              <Input
                Type="text"
                Name="value"
                Placeholder="Valeur"
                DefaultValue={unit.value}
                onChange={e => Change(e, Bloc, key)}
              />

              <Input
                Type="text"
                Name="dice"
                Placeholder="Formule"
                DefaultValue={unit.dice}
                onChange={e => Change(e, Bloc, key)}
              />
              <Btns
                Icon="delete"
                Style={{
                  background: 'transparent',
                }}
                OnClick={e => handleDeleteUnit(key)}
              />
            </div>
          ))}
        </div>
        <Btns Icon="add" OnClick={handleAddUnit} />
      </div>
    </>
  );
};

const ModifBlocList = ({
  Bloc,
  Contain,
  handleDeleteBloc,
  handleAddUnit,
  handleDeleteUnit,
  Change,
  ChangeName,
}) => {
  return (
    <>
      <h3>{AllTexts.titres.modifBlocList}</h3>
      <div className="btnDeleteBloc" onClick={handleDeleteBloc}>
        {AllTexts.btns.deletebloc}
      </div>

      <Input
        Type="text"
        Name="name"
        Placeholder="Nom du bloc"
        DefaultValue={getNameBloc(Bloc)}
        onChange={e => ChangeName(e)}
      />

      <div className="containerInpList">
        {Contain.map((unit, key) => (
          <div className="containerModifUnit" key={key}>
            <Input
              Type="text"
              Name="titre"
              Placeholder="Nom de l'information"
              DefaultValue={unit.titre}
              onChange={e => Change(e, Bloc, key)}
            />
            <Input
              Type="textarea"
              Name="short_desc"
              Placeholder="Courte description"
              DefaultValue={unit.short_desc}
              onChange={e => Change(e, Bloc, key)}
            />

            <Input
              Type="textarea"
              Name="value"
              Placeholder="Contenu"
              DefaultValue={unit.value}
              onChange={e => Change(e, Bloc, key)}
            />
            <Btns
              Icon="delete"
              Style={{
                background: 'transparent',
              }}
              OnClick={e => handleDeleteUnit(key)}
            />
          </div>
        ))}
      </div>
      <Btns Icon="add" OnClick={handleAddUnit} />
    </>
  );
};
