import React, { useContext } from 'react';
import { motion } from 'framer-motion';

import { ThrowdicerContext } from '../../Context/Throwdicer.context';

import Dices from './Dices';

const ThrowDicer = () => {
  const { DicerOpen, AllThrowPrepared } = useContext(ThrowdicerContext);

  const variantsChild = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <motion.div
      className={
        DicerOpen ? 'containerThrowDicer' : 'containerThrowDicer closed'
      }
      variants={variantsChild}
    >
      {AllThrowPrepared.map((throwing, key) => (
        <>
          {throwing.allThrow.map((dice, key2) => (
            <>
              {key2 === 0 && (
                <>
                  <div className="containerExplain" key={key}>
                    {throwing.text}
                  </div>
                  <div className="separator"></div>
                </>
              )}
              <Dices
                Type={throwing.valuedice}
                Value={dice}
                key={key2}
                Key={key + key2}
              />
            </>
          ))}
        </>
      ))}
    </motion.div>
  );
};

export default ThrowDicer;

/*

 {AllThrowPrepared.map((throwing, key) => (
                            <motion.div
                                   key={key}
                                   variants={variantsParent}
                                   initial="hidden"
                                   animate="initial"
                                   transition={{
                                          delay: key * 0.8,
                                   }}
                            >
                                   <div className="containerExplain" key={key}>
                                          {throwing.text}
                                   </div>
                                   <div className="separator"></div>
                                   {throwing.allThrow.map((dice, key2) => (
                                          <Dices
                                                 Type={throwing.valuedice}
                                                 Value={dice}
                                                 key={key2}
                                                 Key={key + (key2 + 1)}
                                          />
                                   ))}
                            </motion.div>
                     ))}

                     */
