import React, { useContext, createContext, useState, useEffect } from 'react';

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const [PosMouse, setPosMouse] = useState({});

  const [InTouch, setInTouch] = useState(false);

  useEffect(() => {
    //On capte la position de la souris
    window.addEventListener('mousemove', e => {
      getMousePos(e);
    });
    window.addEventListener('touchmove', e => {
      getMousePos(e.touches[0]);
    });

    //On capte la fin du touch de la souris ou du doigt
    document.querySelector('body').addEventListener('touchend', EndTouch);
    document.querySelector('body').addEventListener('mouseup', EndTouch);

    //on empêche l'ouverture du clic gauche
    window.addEventListener('contextmenu', e => e.preventDefault());
  }, []);

  function EndTouch() {
    setInTouch(false);
  }

  function getMousePos(e) {
    let y = e.clientY ?? e.touches[0].clientY;
    let x = e.clientX ?? e.touches[0].clientX;
    setPosMouse({ top: y, left: x });
  }

  return (
    <NavContext.Provider value={{ PosMouse, InTouch, setInTouch }}>
      {children}
    </NavContext.Provider>
  );
};
