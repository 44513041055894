import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { HerosContext } from '../../Context/SelectedHeros';

import { getNameBloc } from '../../../Functions/functions';
import AllText from '../../../Data/AllTexts.json';

const Sommaire = ({ CloseMenu }) => {
  const { heros, blocs } = useContext(HerosContext);

  function scrollSmoothTo(e, id) {
    let elem = document.querySelector(id);

    e.preventDefault(); // Stop Page Reloading
    elem &&
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  }

  return (
    <>
      {heros && blocs && (
        <>
          <h4>{AllText.titres.sommaire}</h4>
          <div className="listAside">
            <ul>
              {blocs.map((bloc, key) => (
                <li
                  key={key}
                  onClick={e => {
                    scrollSmoothTo(e, '#bloc_' + bloc.id);
                    CloseMenu();
                  }}
                  className={bloc.type}
                >
                  {getNameBloc(bloc) ? getNameBloc(bloc) : 'Détails'}{' '}
                  {/*<FontAwesomeIcon icon={faArrowRight} />}*/}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Sommaire;
