import React from "react";

const Topform = ({ Color1, Color2 }) => {
       return (
              <div id="Topform">
                     <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 541.06 215.97"
                     >
                            <defs>
                                   <linearGradient id="MyGradient">
                                          <stop
                                                 offset="52%"
                                                 stopColor={Color1 ?? "#151431"}
                                          />
                                          <stop
                                                 offset="100%"
                                                 stopColor={Color2 ?? "#224e55"}
                                          />
                                   </linearGradient>
                            </defs>
                            <g id="Calque_2" data-name="Calque 2">
                                   <g id="Calque_1-2" data-name="Calque 1">
                                          <path
                                                 className="cls-1"
                                                 d="M37,127c16.53,2.09,31.94-8.44,37-24.32,7.92-25.07,50.83-63.42,133.5-59.12,116.72,6.06,89.14,183.21,213.94,169.06s120.72-87.12,115-130.15c-6.67-50-28.31-80-28.31-80H9.23S-18,120.12,37,127Z"
                                          />
                                   </g>
                            </g>
                     </svg>
              </div>
       );
};

export default Topform;
