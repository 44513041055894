import { useState, useEffect } from 'react';
import Connecter from '../ajax/Connecter_v2.class';

export default function useSessionConnect() {
  const [isConnect, setIsConnect] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function askSession() {
      setLoading(true);
      new Connecter('verifSession').connect_to_api().then(res => {
        //console.log(res);
        setIsConnect(res.res);
        setLoading(false);
      });
    }
    askSession();
  }, []);

  return { isConnect, loading };
}
