import React from "react";

const PageForm = ({ H2, Desc, Label, children }) => {
       return (
              <div className="page">
                            <div className="containResume">
                                   <h2>{H2}</h2>
                                   <span>{Desc}</span>
                            </div>
                            <div className="containInps">
                                   {Label && (
                                          <span className="label">{Label}</span>
                                   )}
                                   {children}
                            </div>
              </div>
       );
};

export default PageForm;
