import React from 'react';
import Logo from '../Default/Logo';
import { Link } from 'react-router-dom';
import Btns from '../Default/Btns';

const Header = () => {
  return (
    <nav>
      <div className="inside">
        <Link to="/">
          <Logo />
        </Link>
        <Link to="/app">
          <Btns Text="Ouvrir Alfred" Icon="none" />
        </Link>
      </div>
    </nav>
  );
};

export default Header;
