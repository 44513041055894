import React, { useEffect, useState } from 'react';

import { return_map } from '../../Functions/functions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import dd_icon from '../../assets/templates_icons/dd_icon.png';
import arya_icon from '../../assets/templates_icons/arya_icon.png';
import mage_icon from '../../assets/templates_icons/mage_icon.png';

import IconDice from '../Elements/Default/IconDice';

const Select = ({
  Name,
  Options,
  DefaultSelect,
  OnChange,
  Label,
  Required,
  Opener,
}) => {
  const [Selected, setSelected] = useState(DefaultSelect);
  const [Open, setOpen] = useState(false);

  useEffect(() => {
    handleChange(Selected);
  }, [Selected]);

  useEffect(() => {
    setSelected(DefaultSelect);
  }, [DefaultSelect]);

  function handleClick(option) {
    setSelected(option.id);
  }

  function handleChange(e) {
    if (OnChange) {
      OnChange(e);
    }
  }

  function handleClickOnContainer() {
    if (!Open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  function getImg(option) {
    switch (option.id) {
      case 'DD':
        return <img src={dd_icon} alt={option.name} />;
      case 'arya':
        return <img src={arya_icon} alt={option.name} />;
      case 'mage':
        return <img src={mage_icon} alt={option.name} />;
      case 'creation':
        return <FontAwesomeIcon icon={faPen} />;
      default:
        return '';
    }
  }

  return (
    <div
      className={
        'containerSelect' +
        ' ' +
        Name +
        ' ' +
        (Opener ? 'opener' : '') +
        ' ' +
        (Open ? 'open' : '')
      }
      onClick={handleClickOnContainer}
    >
      {Label && <label htmlFor={Name}>{Label}</label>}

      <div className="containerOptions">
        {Options &&
          return_map(Options).map((option, key) => (
            <div
              className={Selected == option.id ? 'options select' : 'options'}
              value={option.id}
              key={key}
              onClick={e => handleClick(option)}
            >
              {Name === 'Template' && getImg(option)}
              {Name === 'Template' && option.name}
              {Name === 'dice_to_roll' && <IconDice Type={option.id} />}
            </div>
          ))}
      </div>
      <input type="hidden" name={Name} value={Selected} required={Required} />
    </div>
  );
};

export default Select;

/**
 * 
 *  <select
                            name={Name}
                            defaultValue={DefaultSelect}
                            onChange={(e) => handleChange(e)}
                     >
                            {Options &&
                                   returnmap(Options).map((option, key) => (
                                          <option value={option.id} key={key}>
                                                 {option.name}
                                          </option>
                                   ))}
                     </select>
                     
 */
