import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Input from '../Form/Input';
import Btns from '../Elements/Default/Btns';
import Topform from '../Elements/Default/Topform';

import useSessionConnect from '../../hooks/useSessionConnect';
import Connecter from '../../ajax/Connecter_v2.class';

import Dice20 from '../../assets/icons/dices/dice20.svg';
import Dice10 from '../../assets/icons/dices/dice10.svg';

const Login = () => {
  const [params, setParams] = useSearchParams();

  const { isConnect } = useSessionConnect();
  //const [isConnect, setIsConnect] = useState(false);
  let navigate = useNavigate();

  const [inInscription, setInInscription] = useState(false);

  const [identifiant, setIdentifiant] = useState('');
  const [email, setEmail] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [pass, setPass] = useState('');
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    if (isConnect) {
      navigate('/app');
    }
  }, [isConnect, navigate]);

  /**
   * Ajoute le keypress sur Enter pour envoyer le form
   */
  useEffect(() => {
    document.querySelector('body').addEventListener('keypress', handleEnter);

    return () => {
      document
        .querySelector('body')
        .removeEventListener('keypress', handleEnter);
    };
  }, [inInscription, identifiant, email, pseudo, pass]);

  function handleChange(e) {
    switch (e.target.name) {
      case 'identifiant':
        setIdentifiant(e.target.value);
        break;
      case 'password':
        setPass(e.target.value);
        break;
      case 'pseudo':
        setPseudo(e.target.value);
        setIdentifiant(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      default:
        return;
    }
  }

  function connect() {
    new Connecter('connectSession')
      .connect_to_api({
        email: identifiant,
        pseudo: identifiant,
        password: pass,
      })
      .then(res => {
        if (res.res) {
          //c'est bon !
          let url = '/app';
          if (params.get('t')) {
            url = url + '?t=' + params.get('t');
          }
          navigate(url);
        } else {
          setMessageError(res.text);
        }
      });
  }

  function inscription() {
    return new Promise((r, f) => {
      new Connecter('inscriptionSession')
        .connect_to_api({
          email: email,
          pseudo: pseudo,
          password: pass,
        })
        .then(res => {
          if (!res.res) {
            setMessageError(res.text);
          } else {
            connect();
          }
        });
    });
  }

  function handleEnter(e) {
    if (e.key !== 'Enter') {
      return;
    }

    if (inInscription) {
      inscription();
    } else {
      connect();
    }
  }

  return (
    <div className="loginpage">
      <Topform />
      <div className="containerElementFloat" id="dice1">
        <img src={Dice20} alt="" />
      </div>
      <div className="containerElementFloat" id="dice2">
        <img src={Dice10} alt="" />
      </div>
      {!inInscription ? (
        <div className="ContainerFormConnect">
          <form action="post" onSubmit={e => e.preventDefault()}>
            <div className="enTete">
              <h2>Bienvenue</h2>
              <span className="soustitre">
                Ravi de vous revoir parmi nous !
              </span>
            </div>

            <Input
              Type="text"
              Name="identifiant"
              Placeholder="Identifiant"
              Required={true}
              onChange={handleChange}
            />
            <Input
              Type="password"
              Name="password"
              Placeholder="Mot de passe"
              Required={true}
              onChange={handleChange}
            />

            <div className="message_error">{messageError}</div>
            <Btns Icon="none" Text="Se connecter" OnClick={connect} />
            <div
              className="link_inscription"
              onClick={e => setInInscription(true)}
            >
              Besoin d'un compte ?
            </div>
          </form>
        </div>
      ) : (
        <div className="ContainerFormConnect">
          <form action="post" onSubmit={e => e.preventDefault()}>
            <div className="enTete">
              <h2>Créer un compte</h2>
              <span className="soustitre">
                En espérant qu'Alfred vous soit utile !
              </span>
            </div>

            <Input
              Type="text"
              Name="pseudo"
              Placeholder="Pseudo"
              Required={true}
              onChange={handleChange}
            />
            <Input
              Type="text"
              Name="email"
              Placeholder="E-mail"
              Required={true}
              onChange={handleChange}
            />
            <Input
              Type="password"
              Name="password"
              Placeholder="Mot de passe"
              Required={true}
              onChange={handleChange}
            />

            <div className="message_error">{messageError}</div>
            <Btns Icon="none" Text="S'inscrire" OnClick={inscription} />
            <div
              className="link_inscription"
              onClick={e => setInInscription(false)}
            >
              Vous avez déjà un compte ?
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
