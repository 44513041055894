import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import { HerosContext } from '../Context/SelectedHeros';

import Dices from '../../Data/Dices.json';
import AllTexts from '../../Data/AllTexts.json';
import Templates from '../../Data/Templates.json';

import Input from './Input';
import Select from './Select';
import ModifHerosBloc from './Blocs/ModifHerosBloc';

import FormCreateHeros from './FormCreateHeros';
import Popup from '../Elements/Popups/Popup';

const FormModifHeros = ({ setInModifier }) => {
  const { heros, setHeros, blocs, setBlocs, delete_heros, downloadHero } =
    useContext(HerosContext);

  const [shareTemplatepopupVisible, setShareTemplatepopupVisible] =
    useState(false);

  const [linkToShare, setLinkToShare] = useState('');
  const [textInPopupShare, setTextInPopupShare] = useState('Lien à copier');

  /**
   * Supprime le bloc demandé
   * @param {string | int} id sa clef dans le tbaleau Selected.blocs
   */
  function handleDeleteBloc(id) {
    let arr = [...blocs];
    arr.splice(id, 1);

    setBlocs(arr);
  }

  /**
   * Au clic sur "Suppromer le héros"
   */
  function handleClickOnDeleteHeros() {
    let validate = window.confirm(
      'Vous allez supprimer ce héros. En êtes-vous sûr ?',
    );
    if (validate) {
      delete_heros();
      setInModifier(false);
    }
  }

  //Adapte le form selon le template sélectionné
  function handleChangeTemplate(template_id) {
    let selected_template = Templates[template_id];
    let newhero = {
      dice_to_roll: selected_template.dice_to_roll,
      profession: selected_template.profession,
      blocs: selected_template.blocs,
    };
    setHeros(newhero);
    setBlocs(newhero.blocs);
    setInModifier(false);
  }

  function handleCopyLinkShareTemplare() {
    if (linkToShare) {
      navigator.clipboard.writeText(linkToShare).then(() => {
        setTextInPopupShare('Lien copié');
      });
    }
  }

  return (
    <>
      {heros.id ? (
        <form id="form_modif_heros" onSubmit={e => e.preventDefault()}>
          <h1>
            {!heros.id ? AllTexts.titres.addheros : AllTexts.titres.modifheros}
          </h1>
          {!heros.id && (
            <Select
              Name="Template"
              Options={Templates}
              DefaultSelect="creation"
              OnChange={handleChangeTemplate}
              Label="Quel template ?"
              Opener={true}
            />
          )}
          <Select
            Name="dice_to_roll"
            Options={Dices.dices}
            DefaultSelect={
              heros.dice_to_roll === '' || !heros.dice_to_roll
                ? '20'
                : heros.dice_to_roll
            }
            Required={true}
            Label={AllTexts.inputs.dicetoroll}
          />
          <Input
            Type="text"
            Name="nom"
            Placeholder={AllTexts.inputs.herosname}
            DefaultValue={heros.nom}
            Required={true}
          />
          <Input
            Type="text"
            Name="profession"
            Placeholder={AllTexts.inputs.herosprofession}
            DefaultValue={heros.profession}
          />
          <Input
            Type="text"
            Name="img"
            Placeholder={AllTexts.inputs.herosimg}
            DefaultValue={heros.img}
          />
          {blocs &&
            blocs.map((bloc, key) => (
              <ModifHerosBloc
                Bloc={bloc}
                key={key}
                Key={key}
                handleDeleteBloc={handleDeleteBloc}
              />
            ))}
          <div
            id="btnDelHeros"
            onClick={handleClickOnDeleteHeros}
            style={{ order: 100 }}
          >
            {AllTexts.btns.deleteheros}
          </div>

          <small
            id="btnDelHeros"
            onClick={e => {
              downloadHero().then(link => {
                setLinkToShare(link);
              });
              setShareTemplatepopupVisible(true);
            }}
            style={{ order: 100 }}
          >
            Partager ce personnage via un lien
          </small>

          <Popup name="shareTemplate" visible={shareTemplatepopupVisible}>
            <div className="contain-text">
              Cliquez ci-dessous et collez le lien ainsi copié afin de le
              partager.
            </div>
            <motion.div
              className="container_url"
              whileTap={{ scale: 0.9 }}
              onClick={handleCopyLinkShareTemplare}
            >
              <div className="contain_url_text">{textInPopupShare}</div>
              <div className="container-icon-copy">
                <FontAwesomeIcon icon={faCopy} />
              </div>
            </motion.div>
          </Popup>
        </form>
      ) : (
        <FormCreateHeros setInModifier={setInModifier} />
      )}
    </>
  );
};

export default FormModifHeros;
