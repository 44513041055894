import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "./Logo";
import Dice20 from "../../../assets/icons/dices/dice20.svg";

const Loader = ({ actif, Simple }) => {
       const variants = !Simple
              ? {
                       hidden: { y: "-100%" },
                       visible: { x: "0%" },
                }
              : {
                       hidden: { opacity: 1 },
                       visible: { opacity: 0 },
                };

       const variantsSimple = {
              hidden: { opacity: 1 },
              visible: { opacity: 0 },
       };

       return (
              <>
                     <AnimatePresence>
                            {actif && (
                                   <motion.div
                                          className={
                                                 Simple
                                                        ? "loader simple"
                                                        : "loader"
                                          }
                                          animate="visible"
                                          exit="hidden"
                                          variants={variants}
                                          transition={{ duration: 1 }}
                                   >
                                          {/*<Logo />*/}
                                          <motion.img
                                                 src={Dice20}
                                                 alt=""
                                                 animate={{
                                                        scale: [1, 1, 2, 1, 1],
                                                        rotate: [
                                                               0, 380, 0, 0, 0,
                                                        ],
                                                 }}
                                                 transition={{
                                                        ease: "easeInOut",
                                                        repeat: Infinity,
                                                        duration: 2,
                                                 }}
                                          />
                                   </motion.div>
                            )}
                     </AnimatePresence>
              </>
       );
};

export default Loader;
