import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { HerosContext } from '../../Context/SelectedHeros';
import { ListHerosContext } from '../../Context/ListHeros.context';

import Btns from './Btns';

import DefaultImg from '../../../assets/default_avatar.jpg';

const ListHeros = ({ closeMenu, setInModifier, setLoadingSimple }) => {
  const { modifHeros, heros } = useContext(HerosContext);
  const { ListHeros } = useContext(ListHerosContext);

  return (
    <>
      <h4>Vos héros</h4>
      {ListHeros && (
        <ul className="ListHeros listAside">
          {ListHeros.map((hero, key) => (
            <li
              className={
                heros.id === hero.id ? 'heroSelecter selected' : 'heroSelecter'
              }
              key={key}
              onClick={e => {
                setLoadingSimple(true);
                modifHeros(hero.id);
                setInModifier(false);
                closeMenu();
              }}
            >
              <div className="containImage">
                <img
                  src={
                    !hero.img || hero.img === undefined ? DefaultImg : hero.img
                  }
                  alt=""
                />
              </div>

              <span>{hero.nom}</span>
            </li>
          ))}
          <li
            className="heroSelecter"
            onClick={e => {
              modifHeros(false);
              setInModifier(true);
              closeMenu();
            }}
          >
            <div className="containImage">
              <FontAwesomeIcon icon={faPlus} />
            </div>

            <span>Créer un héros</span>
          </li>
          {/*<Btns
            Icon="add"
            Taille="60px"
            OnClick={e => {
              modifHeros(false);
              setInModifier(true);
              closeMenu();
            }}
          />*/}
        </ul>
      )}
    </>
  );
};

export default ListHeros;
