import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

//import Connecter from '../../ajax/Connecter.class';
import Connecter from '../../ajax/Connecter_v2.class';
import { isErrorOnSubmit } from '../../Functions/formModifHeros.func';

import { dynamicSort } from '../../Functions/functions';

import { ListHerosContext } from '../Context/ListHeros.context';

export const HerosContext = createContext();

export const HerosProvider = ({ children, setLoading, setInModifier }) => {
  const [params, setParams] = useSearchParams();
  const [heros, setHeros] = useState({});
  const [blocs, setBlocs] = useState(heros.blocs ?? []);
  const { ListHeros, reloadList } = useContext(ListHerosContext);
  const [inTemplate, setInTemplate] = useState(false);

  /** USE EFFECT */

  useEffect(() => {
    if (params.get('t')) {
      setInTemplate(true);
      createFromTemplate();
    }
  }, []);

  //au changement de liste des héros, récupère le premier héros et l'affiche
  useEffect(() => {
    if (ListHeros && ListHeros.length && !inTemplate) {
      modifHeros(ListHeros[0].id);
    }
  }, [ListHeros]);

  //au changement de héros, on enregistre dans storage son id
  useEffect(() => {
    if (heros && heros.id && inTemplate) {
      setLastIdHerosInStorage(heros.id);
    }
  }, [heros]);

  /** FUNCTIONS */

  function modifHeros(id) {
    return new Promise((r, f) => {
      setLoading(true);
      if (!id) {
        setHeros(false);
      } else if (id === 'last') {
        modifHeros(getLastIdHerosInStorage());
      } else {
        get_heros(id).then(hero => {
          setHeros(hero);
          if (hero.blocs) {
            hero.blocs.forEach(bloc => {
              bloc.contain = JSON.parse(bloc.contain);

              //On trie les élement du contain
              bloc.contain.sort(dynamicSort('titre'));
            });
            setBlocs(hero.blocs);
          }
        });
      }
      setLoading(false);
      r(true);
    });
  }

  function submitFormModifHeros(form = false) {
    return new Promise((r, f) => {
      if (!form) {
        form = document.querySelector('#form_modif_heros');
      }

      if (!form) {
        return;
      }

      let errors = isErrorOnSubmit(form);
      if (errors) {
        console.log(errors);
        return errors;
      } else {
        let infos = {
          nom: form.nom.value,
          profession: form.profession.value,
          dice_to_roll: form.dice_to_roll.value,
          img: form.img.value,
        };

        if (heros && heros.id) {
          infos.id = parseInt(heros.id);
        }

        update_heros(infos).then(res => {
          res = parseInt(res);
          let id = res ?? heros.id;
          reloadList();
          setInModifier(false);
          modifHeros(id);
          r(true);
        });
      }
    });
  }

  /*** API */

  /**
   * Sélectionne un héros et l'affiche
   * @param {int} id_heros Id du héros dans la BDD
   * @returns
   */
  function get_heros(id_heros) {
    return new Promise((r, f) => {
      new Connecter('get_heros').connect_to_api({ id: id_heros }).then(res => {
        if (res.res) {
          let hero = res.text[0];
          r(hero);
        } else {
          r(false);
        }
      });
    });
  }

  /**
   * Modifie le héros de SelectedHeros
   * @param {Object} infos Contient tous les éléments pour modifier la base de données
   * @returns
   */
  function update_heros(infos, is_template = false) {
    return new Promise((r, f) => {
      if (blocs && blocs.length) {
        infos.blocs = JSON.stringify(blocs);
      } else {
        infos.blocs = false;
      }

      if (infos.id) {
        new Connecter('update_heros').connect_to_api(infos).then(res => {
          r(true);
        });
      } else {
        new Connecter('add_heros').connect_to_api(infos).then(res => {
          reloadList();
          r(true);
        });
      }
    });
  }

  /**
   * Supprime le Selected Heros
   * @returns
   */
  function delete_heros() {
    if (Object.keys(heros).length === 0) {
      return;
    }

    return new Promise((r, f) => {
      new Connecter('delete_heros')
        .connect_to_api({ id: heros.id })
        .then(res => {
          if (res.res) {
            reloadList();
            modifHeros(ListHeros[0]);
            r(res);
          } else {
            r(false);
          }
        });
    });
  }

  /**
   * Télécharge un fichier json avec les infos du perso
   */
  async function downloadHero() {
    return new Promise((r, f) => {
      let to_download = Object.assign({}, heros);
      //to_download.blocs = JSON.stringify(to_download.blocs);
      delete to_download.id;
      delete to_download.id_user;
      delete to_download.id_campagne;

      to_download.blocs.forEach(bloc => {
        delete bloc.id;
        delete bloc.id_heros;
        delete bloc.date_creation;
      });

      let date = new Date();
      let titre =
        'sheet_' +
        date.getFullYear() +
        '_' +
        date.getMonth() +
        '_' +
        date.getDate() +
        '_' +
        to_download.nom +
        '.json';

      new Connecter('share_template')
        .connect_to_api({ temp: JSON.stringify(to_download) })
        .then(res => {
          if (res && res.text) {
            r(res.text);
          }
        });
    });
  }

  function createFromTemplate() {
    let t = params.get('t');
    if (t) {
      new Connecter('receiveTemplate')
        .connect_to_api({ t: params.get('t') })
        .then(res => {
          if (res && res.text) {
            let temp = res.text;
            temp.template = true;
            modifHeros(false);
            setHeros(temp);
            setBlocs(temp.blocs);
            setInModifier(true);
            return true;
          } else {
            return false;
          }
        });
    }
  }

  /** SESSION STORAGE */

  function setLastIdHerosInStorage(id) {
    sessionStorage.setItem('lastHerosId', id);
  }
  function getLastIdHerosInStorage() {
    return sessionStorage.getItem('lastHerosId');
  }

  return (
    <HerosContext.Provider
      value={{
        heros,
        setHeros,
        blocs,
        setBlocs,
        modifHeros,
        update_heros,
        submitFormModifHeros,
        delete_heros,
        downloadHero,
      }}
    >
      {children}
    </HerosContext.Provider>
  );
};
