import ContainBlocData from '../Data/ContainBloc.json';

/**
 * Ajoute un bloc et prépare son contenu suivant le type demandé
 * @param {String} type
 */
export function handleAddBloc(SelectedHeros, type = 'LIST', setBlocs) {
  let newbloc = {
    contain: [],
    type: type,
    name: '',
  };

  newbloc.contain.push(Object.assign({}, ContainBlocData[type]));

  if (!SelectedHeros) {
    SelectedHeros = {};
  }

  if (!SelectedHeros.blocs) {
    SelectedHeros.blocs = [];
  }

  let arr = [...SelectedHeros.blocs];
  arr.push(newbloc);
  setBlocs(arr);

  setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, 100);
  //document.querySelector("body").scrollBy({ top: 1000000000 });
}

export function isErrorOnSubmit(form) {
  for (const element of form) {
    if (element.required && !element.value) {
      return element;
    }
  }

  return false;
}
